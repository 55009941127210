<template>
  <div class="refundOrderdetail" id="refundOrderdetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="title">
      <div></div>
      <div>基本信息</div>
    </div>
    <el-form label-width="80px">
      <el-form-item label="订单号:">
        <div>
          {{ orderdetail.coding }}
        </div>
        <div class="name">
          下单时间:
          <span>{{ orderdetail.addTime }}</span>
        </div>
        <div class="name" v-if="orderdetail.orderType != 5">
          订单类型:
          <span>{{ orderdetail.strOrderType }}</span>
        </div>
        <div class="name" v-else>
          订单类型:
          <span>美食订单</span>
        </div>
      </el-form-item>
      <el-form-item label="支付方式:">
        <div>
          {{ orderdetail.strEndPayWay }}
        </div>
        <div class="name">
          总数量:
          <span>{{ orderdetail.totalCount }}</span>
        </div>
        <div class="name" v-if="orderdetail.status == 9 && orderdetail.bookList">
          未预约数量:
          <span>{{ orderdetail.count }}</span>
        </div>
        <div class="name" v-if="orderdetail.childCount">
          儿童数量:
          <span>{{ orderdetail.childCount }}</span>
        </div>
        <div class="name" v-if="type!=5 && type !=6">
          订单来源:
          <span>{{ orderdetail.strSource }}</span>
        </div>
      </el-form-item>
      <el-form-item label="预计获得积分:">
        <div>
          <span>{{ orderdetail.getIntegral || "0积分" }}</span>
        </div>
        <div class="name">
          客户备注:
          <span>{{ orderdetail.remark || "客户暂无备注" }}</span>
        </div>
        <div class="name">
          支付银行:
          <span>{{ orderdetail.bankName }}</span>
        </div>
      </el-form-item>
      <el-form-item label="佣金结算方式:" v-if="orderdetail.accountWay">
        <div>
          <span>{{ orderdetail.accountWay }}</span>
        </div>
        <div class="name" v-if="orderdetail.bookOrderType">
          分次预约订单说明:
          <span>{{ orderdetail.bookOrderType }}</span>
        </div>
        <div class="name" v-if="orderdetail.fatherCoding">
          <el-button type="primary" @click="goOrderdetail(orderdetail.fatherCoding)">查看主订单</el-button>
        </div>
      </el-form-item>
      <el-form-item label="订单状态:">
        <!-- <span v-if="orderdetail.orderType != 5">{{
          orderdetail.type === 6 ? orderdetail.groupBuyStrStatus : orderdetail.strStatus
        }}</span>
        <span v-if="orderdetail.orderType == 5">{{
          orderdetail.status == 3 ? "待使用" : orderdetail.strStatus
        }}</span> -->
        <span>{{  orderdetail.strStatus}}</span>

        <el-button type="primary" v-if="orderdetail.status == 2" @click="onsureOrder"
          >确认订单</el-button
        >
        <el-button
          type="primary"
          v-if="orderdetail.groupBuyStatus == 0 && orderdetail.status !== 1"
          @click="onGroupOk"
          >确认成团</el-button
        >
        <el-button
          type="primary"
          v-if="
            (orderdetail.type !== 6 &&
              (orderdetail.status == 2 ||
                orderdetail.status == 9 ||
                orderdetail.status == 3 ||
                orderdetail.status == 1)) ||
            (orderdetail.type === 6 &&
              (orderdetail.status == 2 ||
                orderdetail.status == 9 ||
                orderdetail.status == 3 ||
                orderdetail.status == 1) &&
              (orderdetail.groupBuyStatus == 1 || orderdetail.groupBuyStatus == 2))
          "
          @click="onCancelOrder"
          >取消订单</el-button
        >
        <el-button
          type="primary"
          v-if="orderdetail.status == 3 && orderdetail.strOrderType == '酒店订单'"
          @click="onSureComing"
          >确定入住</el-button
        >
        <el-button
          type="primary"
          v-if="
            orderdetail.status == 3 &&
            orderdetail.strOrderType !== '酒店订单' &&
            orderdetail.orderType != 5
          "
          @click="onSureComing"
          >{{ orderdetail.type === 4 ? "待领取" : "确认游玩" }}</el-button
        >
        <el-button
          type="primary"
          v-if="orderdetail.status == 3 && orderdetail.orderType == 5"
          @click="onSureComing"
          >确认核销</el-button
        >
        <el-button
          type="primary"
          v-if="orderdetail.status == 6"
          @click="dialogVisible = true"
          >查看退款详情</el-button
        >
        <el-button
          type="primary"
          v-if="orderdetail.status == 7"
          @click="dialogVisible = true"
          >查看退款详情</el-button
        >
        <el-button type="primary" @click="mandatoryRefund">强制退款</el-button>
        <el-button type="primary" @click="setMod">设置分销比例</el-button>
        <el-button
          type="primary"
          @click="dialogVisibleOrderStatus = true"
          v-if="
            this.orderdetail.status == 2 ||
            this.orderdetail.status == 3 ||
            this.orderdetail.status == 9
          "
          >修改订单状态</el-button
        >
        <el-button
          type="primary"
          @click="dialogVisibleOrderTime = true"
          v-if="this.orderdetail.status == 2 || this.orderdetail.status == 3"
          >修改出行时间</el-button
        >
      </el-form-item>
    </el-form>
    <div class="title">
      <div></div>
      <div>下单人信息</div>
    </div>
    <el-form ref="form" label-width="80px">
      <el-form-item label="下单人姓名:">
        <div>
          <span>{{ orderdetail.trueName }}</span>
        </div>
        <div>
          下单手机:
          <span>{{ orderdetail.xdPhone }}</span>
        </div>
        <div  v-if="orderdetail.idCardInfo && orderdetail.cardType==1">
          身份证号:
          <span >{{ orderdetail.idCardInfo }}</span>
        </div>
      </el-form-item>
    </el-form>
    <div class="title">
      <div></div>
      <div>出行时间</div>
    </div>
    <el-form ref="form" label-width="80px">
      <el-form-item :label="orderdetail.orderType == 1 ? '入住时间' : '游玩时间'">
        <div>
          <span v-if="orderdetail.beginTime">{{ orderdetail.beginTime }}</span>
        </div>
        <div v-if="orderdetail.endTime && orderdetail.orderType == 1">
          离开时间:
          <span>{{ orderdetail.endTime }}</span>
        </div>
        <div>
          是否改期:
          <span>{{ orderdetail.isChange }}</span>
        </div>
      </el-form-item>
    </el-form>
    <div class="title">
      <div></div>
      <div>客户信息</div>
    </div>
    <el-form label-width="80px">
      <el-form-item label="联系人:">
        <div>
          <span>{{ orderdetail.linkMan }}</span>
        </div>
        <div class="name">
          手机:
          <span>{{ orderdetail.phone }}</span>
        </div>
        <div class="name" v-if="orderdetail.idCardInfo && orderdetail.cardType==2">
          身份证号:
          <span >{{ orderdetail.idCardInfo }}</span>
        </div>
      </el-form-item>
      <el-form-item label="游玩人:" class="palyer" v-if="orderdetail.peopleInfos">
        <div
          style="width: 300px"
          v-for="item in orderdetail.peopleInfos"
          :key="item.idNo"
        >
          <span>{{ item.trueName }}</span>
          <span>{{ item.strType }}:</span>
          <span>{{ item.idNo }}</span>
        </div>
      </el-form-item>
    </el-form>
    <div class="title">
      <div></div>
      <div>订单价格:</div>
    </div>
    <el-form label-width="80px">
      <el-form-item label="总价:">
        <div>
          <span>¥{{ orderdetail.totalPrice }}</span>
        </div>
        <div v-if="orderdetail.roomDifferent">
          房差:
          <span>¥ {{ orderdetail.roomDifferent }}</span>
        </div>
        <div v-if="orderdetail.changeFee > 0">
          改期补差价:
          <span>¥ {{ orderdetail.changeFee }}</span>
        </div>
        <div v-if="orderdetail.upgradePrice !== null || orderdetail.upgradePrice > 0">
          补差价:
          <span> ¥{{ orderdetail.upgradePrice }}</span>
        </div>
        <div v-if="orderdetail.isChild != null">
          是否子订单:
          <span> {{ orderdetail.isChild == 1 ? '是' : '否' }}</span>
        </div>
      </el-form-item>
      <el-form-item label="总积分:">
        <div>
          <span>{{ orderdetail.totalIntegral }}</span>
        </div>
        <div v-if="orderdetail.bookList">
          最终支付:
          <span>¥{{ orderdetail.totalFee }}</span>
        </div>
        <div v-else>
          最终支付:
          <span>¥{{ orderdetail.endPrice }}</span>
        </div>
        <div v-if="orderdetail.bookList && orderdetail.status == 9 && orderdetail.orderType != 6">
          剩余订单价格:
          <span>¥{{ orderdetail.endPrice }}</span>
        </div>
        <div v-if="orderdetail.upgradePrice">
          二次预约价格:
          <span>¥{{ orderdetail.upgradePrice }}</span>
        </div>
      </el-form-item>
      <el-form-item>
     
        <div v-if="orderdetail.fatherTotalFee">
          礼券总价格:
          <span> ¥{{ orderdetail.fatherTotalFee }}</span>
        </div>
     
        <div v-if="orderdetail.fatherEndFee">
          礼券最终支付价格:
          <span> {{ orderdetail.fatherEndFee}}</span>
        </div>
       
       
        
      </el-form-item>
    <el-form-item>
      <div v-if="orderdetail.prodTotalFee">
          商品价格:
          <span> {{ orderdetail.prodTotalFee }}</span>
        </div>
        <div v-if="orderdetail.upFee">
          二次预约价格:
          <span> {{ orderdetail.upFee }}</span>
        </div>
    </el-form-item>
     
    </el-form>
    <div class="title">
      <div></div>
      <div>活动信息:</div>
    </div>
    <el-form ref="form" label-width="80px">
      <el-form-item label="优惠券名称:" v-if="orderdetail.couponName">
        <div>
          <span>{{ orderdetail.couponName }}</span>
        </div>
        <div class="name" v-if="orderdetail.couponPrice">
          优惠价格:
          <span>¥{{ orderdetail.couponPrice }}</span>
        </div>
      </el-form-item>
      <el-form-item label="连住活动:" v-if="orderdetail.connectFee>0">
        <div>
          <span>{{ orderdetail.connectTitle }}</span>
        </div>
        <div class="name" >
          连住优惠价格:
          <span>¥{{ orderdetail.connectFee }}</span>
        </div>
      </el-form-item>
      <el-form-item label="提前预订活动:" v-if="orderdetail.advanceFee>0">
        <div>
          <span>{{ orderdetail.advanceTitle }}</span>
        </div>
        <div class="name" >
          提前预订价格:
          <span>¥{{ orderdetail.advanceFee }}</span>
        </div>
      </el-form-item>
      <el-form-item label="会员折扣名称:" v-if="orderdetail.discountInfo">
        <div>
          <span>{{ orderdetail.discountInfo }}</span>
        </div>
        <div class="name" v-if="orderdetail.discountPrice">
          会员折扣价格:
          <span>¥{{ orderdetail.discountPrice }}</span>
        </div>
      </el-form-item>
      <el-form-item :label="orderdetail.fullTab + ':'" v-if="orderdetail.fullFee > 0">
        <div class="name" style="margin: 0 0 0 3px">
          <span>-¥{{ orderdetail.fullFee }}</span>
        </div>
      </el-form-item>
      <el-form-item label="拼团名称:" v-if="orderdetail.groupBuyName">
        <div>
          <span>{{ orderdetail.groupBuyName }}</span>
        </div>
        <div class="name" v-if="orderdetail.groupBuyStrStatus">
          拼团状态:
          <span>{{ orderdetail.groupBuyStrStatus }}</span>
        </div>
      </el-form-item>
      <el-form-item label="剩余时间:" v-if="orderdetail.remainTime">
        <div>
          <span>{{ orderdetail.countDown }}</span>
        </div>
        <div class="name" v-if="orderdetail.peopleCount">
          成团人数:
          <span>{{ orderdetail.peopleCount }}</span>
        </div>
      </el-form-item>
      <el-form-item label="成团剩余人数:" v-if="orderdetail.endPeople">
        <div>
          <span>{{ orderdetail.endPeople }}</span>
        </div>
      </el-form-item>
      <el-form-item label="订单明细:" v-if="orderdetail.seckillInfo">
        <div>
          {{ orderdetail.seckillInfo }}
        </div>
      </el-form-item>
      <el-form-item label="活动标题:" v-if="orderdetail.activityTitle">
        <div>
          <span>{{ orderdetail.activityTitle }}</span>
        </div>
        <div class="name" v-if="orderdetail.areaName">
          区域名称:
          <span>{{ orderdetail.areaName }}</span>
        </div>
      </el-form-item>
    </el-form>
    <div class="title">
      <div></div>
      <div>产品信息:</div>
    </div>
    <el-table :data="orderdetail.packInfos" style="width: 70%" border v-show="orderdetail.packInfos && orderdetail.orderType == 6">
      <el-table-column align="center" prop="prodName" label="商品名称" >
      </el-table-column>
      <el-table-column align="center" prop="prodType" label="商品类型">
      </el-table-column>
      <el-table-column align="center" prop="showImg" label="展示图" width="width">
        <template slot-scope="{ row }">
          <el-image
            style="width: 50px; height: 50px"
            :src="row.showImg"
            :preview-src-list="[row.showImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="prodFee" label="售价" >
      </el-table-column>
      <el-table-column align="center" prop="endFee" label="结算价" >
      </el-table-column>
      <el-table-column align="center" prop="totalCount" label="总数" >
      </el-table-column>
      <el-table-column align="center" prop="useCount" label="已用数量" >
      </el-table-column>
    </el-table>
    <el-table :data="[orderdetail]" style="width: 50%" border v-if="!orderdetail.packInfos">
      <el-table-column align="center" prop="prodImg" label="产品图片" width="width">
        <template slot-scope="{ row }">
          <el-image
            v-if="row.prodImg"
            style="width: 50px; height: 50px"
            :src="row.prodImg"
            :preview-src-list="[row.prodImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="prodName" label="产品名称" width="width">
      </el-table-column>
    </el-table>

    <!-- <div class="title" v-if="orderdetail.roomExpend">
      <div></div>
      <div>费用说明:</div>
    </div>
    <div v-if="orderdetail.roomExpend">
      {{ orderdetail.roomExpend }}
    </div> -->
    <div class="title" v-if="orderdetail.prodRemark">
      <div></div>
      <div>费用明细:</div>
    </div>
    <div v-if="orderdetail.prodRemark">
      {{ orderdetail.prodRemark }}
    </div>
    <div class="title" v-if="orderdetail.bookList">
      <div></div>
      <div>预约信息:</div>
    </div>
    <el-table :data="orderdetail.bookList" style="width: 70%" border v-if="orderdetail.bookList && orderdetail.orderType != 6">
      <el-table-column align="center" prop="prodName" label="预约商品">
      </el-table-column>
      <el-table-column align="center" prop="bookCoding" label="预约单号" >
      </el-table-column>
      <el-table-column align="center" prop="bookTime" label="预约时间" >
      </el-table-column>
      <el-table-column align="center" prop="count" label="预约数量" >
      </el-table-column>
      <el-table-column align="center" prop="linkMan" label="联系人" >
      </el-table-column>
      <el-table-column align="center" prop="phone" label="联系电话" >
      </el-table-column>
      <el-table-column align="center" prop="payFee" label="补差价" >
      </el-table-column>
      <el-table-column align="center" prop="strStatus" label="预约状态" >
      </el-table-column>
      <el-table-column align="center" label="操作" >
          <template slot-scope="{ row }">
            <el-button
              size="mini"
              @click="goOrderdetail(row.bookCoding)"
              v-if="row.bookCoding != coding"
              type="primary"
              >查看详情
            </el-button>
          </template>
      </el-table-column>
    </el-table>
    <el-table :data="orderdetail.packOrders" style="width: 70%" border v-if="orderdetail.packOrders && orderdetail.orderType == 6">
      <el-table-column align="center" prop="orderId" label="订单Id" >
      </el-table-column>
      <el-table-column align="center" prop="prodName" label="预约商品">
      </el-table-column>
      <el-table-column align="center" prop="coding" label="预约单号" >
      </el-table-column>
      <el-table-column align="center" prop="count" label="预约数量" >
      </el-table-column>
      <el-table-column align="center" prop="status" label="订单状态" >
      </el-table-column>
      <el-table-column align="center" label="操作" >
          <template slot-scope="{ row }">
            <el-button
              size="mini"
              @click="goOrderdetail(row.coding,row.orderType,row.orderId)"
              v-if="row.coding != coding"
              type="primary"
              >查看详情
            </el-button>
          </template>
      </el-table-column>
    </el-table>
    <div class="title" v-if="orderdetail.redeemInfo && orderdetail.redeemInfo.length > 0">
      <div></div>
      <div>核销信息:</div>
    </div>
    <el-table
      :data="orderdetail.redeemInfo"
      style="width: 50%"
      border
      v-if="orderdetail.redeemInfo && orderdetail.redeemInfo.length > 0"
    >
      <el-table-column align="center" prop="addTime" label="核销时间" width="width">
      </el-table-column>

      <el-table-column align="center" prop="redeemName" label="核销地点" width="width">
      </el-table-column>

      <el-table-column
        align="center"
        prop="redeemUserName"
        label="核销人员"
        width="width"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="redeemUserPhone"
        label="核销人员手机号"
        width="width"
      >
      </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" width="width">
      </el-table-column>
    </el-table>
    <div class="title" v-if="orderdetail.upgradeId > 0">
      <div></div>
      <div>升级信息:</div>
    </div>
    <el-table
      v-if="orderdetail.upgradeId > 0"
      border
      :data="[orderdetail]"
      style="width: 50%"
    >
      <el-table-column align="center" prop="upProdImg" label="产品图片" width="width">
        <template slot-scope="{ row }">
          <img src="" alt="" />
          <img style="width: 50px; height: 50px" :src="row.upProdImg" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="upProdName" label="产品名称" width="width">
      </el-table-column>
      <el-table-column align="center" prop="upgradeCoding" label="升级单号" width="width">
      </el-table-column>
    </el-table>
    <div class="title">
      <div></div>
      <div>操作流水:</div>
    </div>
    <div style="margin-top: 10px" v-for="item in orderdetail.masterRemark" :key="item">
      {{ item }}
    </div>
    <el-button type="primary" style="margin-top: 20px" @click="remarksShow = true">
      添加备注</el-button
    >

    <div class="title" v-if="orderdetail.strOrderType == '酒店订单'">
      <div></div>
      <div>客房每日价格:</div>
    </div>
    <el-table
      v-if="orderdetail.strOrderType == '酒店订单'"
      border
      :data="orderdetail.roomPriceDayModel"
      style="width: 50%"
    >
      <el-table-column prop="addTime" label="时间" width="width"> </el-table-column>
      <el-table-column prop="price" label="价格" width="width"> </el-table-column>
    </el-table>

    <div class="title" v-if="orderdetail.type == 6">
      <div></div>
      <div>参团人信息:</div>
    </div>
    <el-table
      v-if="orderdetail.type == 6"
      border
      :data="orderdetail.groupBuys"
      style="width: 50%"
    >
      <el-table-column prop="nickName" label="昵称" width="width"> </el-table-column>
      <el-table-column prop="headImg" label="头像" width="width">
        <template slot-scope="{ row }">
          <el-image style="width: 40px; height: 40px" :src="row.headImg"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="isTeam" label="是否为团长" width="width">
        <template slot-scope="{ row }">
          {{ row.isTeam === 0 ? "是" : "不是" }}
        </template>
      </el-table-column>
      <el-table-column prop="isPay" label="是否支付" width="width">
        <template slot-scope="{ row }">
          {{ row.isPay === 0 ? "已支付" : "未支付" }}
        </template>
      </el-table-column>
      <el-table-column prop="count" label="数量" width="width"> </el-table-column>
      <el-table-column prop="coding" label="订单号" width="150"> </el-table-column>
      <el-table-column align="center" label="操作" >
    <template slot-scope="{ row }">
      <el-button
        size="mini"
        @click="goOrderdetail(row.coding)"
        v-if="row.coding != coding"
        type="primary"
        >查看详情
      </el-button>
    </template>
</el-table-column>
    </el-table>
    <el-button type="primary" @click="onPrint">打印</el-button>
    <el-button type="primary" class="return" @click="$router.go(-1)">返回</el-button>
  
    <div class="title" >
      <div></div>
      <div>上传图片:</div>
    </div>
    <send-image :maxLength="9" type="more" :images="imgs.join(',')" @addImageStrs="addImageStrs"></send-image>
    <el-button type="primary" @click="saveImgs">保存图片</el-button>

    <el-dialog
      :close-on-click-modal="false"
      title="修改订单状态"
      :visible.sync="dialogVisibleOrderStatus"
      width="30%"
    >
      <!-- 选择框 -->
      <el-form :model="orderStatus" ref="orderStatusRef" label-width="80px">
        <el-form-item label="订单状态:">
          <el-select v-model="orderStatus.status" placeholder="请选择">
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            type="textarea"
            :rows="2"
            style="width: 60%; margin: 10px 0"
            v-model="orderStatus.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleOrderStatus = false">取 消</el-button>
        <el-button type="primary" @click="orderStatusSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="退款信息"
      :visible.sync="dialogVisible"
      width="width"
    >
      <el-form
        label-width="80px"
        :rules="backInfoRules"
        :model="backInfo"
        ref="backInfoRef"
      >
        <el-form-item label="退款原因:">
          <span>{{ orderdetail.refundRemark }}</span>
        </el-form-item>

        <el-form-item label="申请时间:">
          <span>{{ orderdetail.refund_time }}</span>
        </el-form-item>
        <el-form-item label="商品价格:">
          <span>{{ orderdetail.totalPrice }}</span>
        </el-form-item>
        <!-- <el-form-item
         prop="refundIntegral"
          label="退款积分:"
          v-if="orderdetail.refundIntegral > 0 && orderdetail.status !== 7"
        /> -->
        <el-form-item :label="`${orderdetail.status == 6 ? '建议退款' :  orderdetail.status == 7 ||  orderdetail.status == 8 ? '已退' :  '' }金额:`">
          <span>{{ orderdetail.refundPrice }}</span>
        </el-form-item>
        <el-form-item label="升级金额:">
          <span>{{ orderdetail.upgradePrice }}</span>
        </el-form-item>
        <el-form-item label="改期金额:">
          <span>{{ orderdetail.changeFee }}</span>
        </el-form-item>

        <el-form-item
          prop="prodFee"
          v-if="orderdetail.status === 6 && orderdetail.refundIntegral <= 0"
          label="商品退款金额:"
        >
          <el-input type="number" v-model="backInfo.prodFee" @blur="astrictProdFee
"></el-input>
        </el-form-item>

        <el-form-item
          prop="upFee"
          v-if="orderdetail.status === 6 && orderdetail.refundIntegral <= 0"
          label="升级退款金额:"
        >
          <el-input type="number" v-model="backInfo.upFee" @blur="astrictUpFee
"></el-input>
        </el-form-item>

        <el-form-item
          prop="changeFee"
          v-if="orderdetail.status === 6 && orderdetail.refundIntegral <= 0"
          label="改期退款金额:"
        >
          <el-input type="number" v-model="backInfo.changeFee" @blur="astrictChangeFee
"></el-input>
        </el-form-item>

        <!-- <el-form-item
          label="确认退款金额:"
          v-if="orderdetail.status === 6 && orderdetail.refundIntegral <= 0"
        >
          <el-input type="number" v-model="refundPrice"></el-input>
        </el-form-item> -->
        <!-- <el-form-item
          label="升级金额:"
          v-if="orderdetail.status === 6"
        >
          <el-input type="number" v-model="upgradePrice"></el-input>
        </el-form-item> -->

        <!-- <el-form-item
         prop="refundIntegral"
          label="确认退款积分:"
          v-if="orderdetail.refundIntegral > 0 && orderdetail.status !== 7"
        >
          <el-input type="number" v-model="backInfo.refundIntegral"></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" v-if="orderdetail.status === 6">
        <el-button @click="onRefuseOrder">拒绝退款</el-button>
        <el-button type="primary" @click="onrefundOk">确定退款</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="取消订单原因"
      :visible.sync="cancelOrderShow"
      width="30%"
    >
      <el-input
        type="textarea"
        v-model="cancelReason"
        placeholder="请填写取消订单原因"
        rows="10"
      ></el-input>
      <div slot="footer">
        <el-button @click="cancelOrderShow = false">取 消</el-button>
        <el-button type="primary" @click="oncancelOk">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="拒绝退款原因"
      :visible.sync="refuseOrderShow"
      width="30%"
    >
      <el-input
        type="textarea"
        v-model="refuseOrderReason"
        placeholder="请填写拒绝退款原因"
        rows="10"
      ></el-input>
      <div slot="footer">
        <el-button @click="refuseOrderShow = false">取 消</el-button>
        <el-button type="primary" @click="onRefuseOrderOk">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="添加订单备注"
      :visible.sync="remarksShow"
      width="30%"
    >
      <el-input
        type="textarea"
        v-model="orderRemarks"
        placeholder="请填写订单备注"
        rows="10"
      ></el-input>
      <div slot="footer">
        <el-button @click="remarksShow = false">取 消</el-button>
        <el-button type="primary" @click="onAddRemark">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="强制退款"
      :visible.sync="checkBuyInfoShow"
      width="45%"
      :close-on-click-modal="false"
    >
      <el-form ref="buyInfoRef" :model="buyInfo" :rules="buyInfoRules" label-width="80px">
        <div style="display: flex">
          <el-form-item label="商品价格">
            <el-input disabled v-model="buyInfo.totalFee"></el-input>
          </el-form-item>
          <el-form-item label="升级价格">
            <el-input disabled v-model="buyInfo.upFee"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="已退款价格">
            <el-input disabled v-model="buyInfo.refundPrice"></el-input>
          </el-form-item>
          <el-form-item label="订单总价格">
            <el-input disabled v-model="buyInfo.endPrice"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="退款方式" prop="isAll">
          <div>
            <el-radio v-model="buyInfo.isAll" :label="1">全部退款</el-radio>
            <el-radio v-model="buyInfo.isAll" :label="2">部分退款</el-radio>
          </div>
        </el-form-item>
        <div v-if="buyInfo.isAll == 2">
          <el-form-item label="商品退款" prop="refundFee">
            <el-input v-model="buyInfo.refundFee"></el-input>
          </el-form-item>
          <el-form-item label="升级退款" prop="upRefundFee">
            <el-input v-model="buyInfo.upRefundFee"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            style="width: 100%"
            v-model="buyInfo.remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="checkBuyInfoShow = false">取 消</el-button>
        <el-button type="primary" @click="submitBuyInfo">确 定</el-button>
      </span>
      <div>
        说明：<br />
        1、该退款方式仅用于全微信支付的订单<br />
        2、该退款方式仅限于订单状态为 已支付 待预约 已预约 已确认 的状态<br />
        3、待评价 已完成的订单请手动退款 并且追回积分和分销<br />
        4、该退款方式会更改订单所得积分和分销金额 ，以最终剩余价格为准<br />
        5、本次退款不涉及库存回滚 请手动操作库存<br />
      </div>
    </el-dialog>
    <el-dialog
      title="设置分销"
      :visible.sync="modifyInfoShow"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form
        ref="modifyInfoRef"
        :model="modifyInfo"
        :rules="modifyInfoRules"
        label-width="80px"
      >
        <el-form-item label="店长人ID" prop="refereeId">
          <el-input v-model.number="modifyInfo.refereeId"></el-input>
        </el-form-item>
        <el-form-item label="一级分销比例" prop="firstRatio">
          <el-input v-model="modifyInfo.firstRatio"></el-input>
        </el-form-item>
        <el-form-item label="二级分销比例" prop="secondRatio">
          <el-input v-model="modifyInfo.secondRatio"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modifyInfoShow = false">取 消</el-button>
        <el-button type="primary" @click="submitModifyInfo">确 定</el-button>
      </span>
      <div>
        说明： <br />
        1、订单有店长的情况不会更改店长id<br />
        2、请慎重操作<br />
      </div>
    </el-dialog>
    <el-dialog
      title="修改出行时间"
      :visible.sync="dialogVisibleOrderTime"
      width="30%"
      :close-on-click-modal="false"
    >
      <div style="margin 0 0 10px 0">
        <div>时间</div>
        <el-date-picker
          v-model="OrderTimeInfo.date"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 60%; margin: 10px 0"
        >
        </el-date-picker>
      </div>
      <div>
        <div>备注</div>
        <el-input
          type="textarea"
          :rows="2"
          style="width: 60%; margin: 10px 0"
          v-model="OrderTimeInfo.setTimeRemark"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleOrderTime = false">取 消</el-button>
        <el-button type="primary" @click="submitTimeInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  refundOrderdetail,
  confirmOrder,
  sureComing,
  cancelOrder,
  refundCancel,
  refundOk,
  orderAddRemark,
  confirmGroup,
  selectNoReview,
  checkBuyInfo,
  setModData,
  mandatoryRefundData,
  newBack,
  orderStatus,
  orderTime,
  uoLoadImgs
} from "../../api/order";
import SendImage from "@/components/sendImage.vue";
export default {
  name: "orderdetail",
  components: {
    SendImage,
  },
  data() {
    return {

      imgs: [],

      dialogVisible: false,
      cancelOrderShow: false,
      refuseOrderShow: false,
      remarksShow: false,
      dialogVisibleOrderStatus: false,
      orderStatus: {
        status: "",
        remark: "",
      },
      dialogVisibleOrderTime: false,
      setTimeRemark: "",
      OrderTimeInfo: {
        date: [],
        setTimeRemark: "",
      },
      data: [],
      cancelReason: "",
      refuseOrderReason: "",
      orderRemarks: "",
      orderdetail: {},
      refundPrice: 200,
      refundIntegral: 200,
      type: "",
      checkBuyInfoShow: false,
      backInfo: {
        prodFee: 0,
        upFee: 0,
        changeFee: 0,
        refundIntegral: 0,
      },
      backInfoRules: {
        prodFee: [{ required: true, message: "请输入商品退款金额", trigger: "blur" }],
        upFee: [{ required: true, message: "请输入升级退款金额", trigger: "blur" }],
        changeFee: [{ required: true, message: "请输入改期退款金额", trigger: "blur" }],
        refundIntegral: [{ required: true, message: "请输入退款积分", trigger: "blur" }],
      },

      buyInfo: {
        totalFee: "",
        upFee: "", // 升级价格
        endPrice: "", //最终支付价格
        refundPrice: "", //已退款金额
        isAll: 1,
        refundFee: 0,
        upRefundFee: 0,
        remark: "",
      },
      modifyInfoShow: false,
      modifyInfo: {
        refereeId: "",
        firstRatio: 0.07,
        secondRatio: 0.02,
      },
      buyInfoRules: {
        isAll: [{ required: true, message: "请选择退款方式", trigger: "change" }],
        refundFee: [{ required: true, message: "请输入商品退款金额", trigger: "change" }],
        upRefundFee: [
          { required: true, message: "请输入升级退款金额", trigger: "change" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "change" }],
      },
      modifyInfoRules: {
        refereeId: [{ required: true, message: "请输入店长人ID", trigger: "change" }],
        firstRatio: [
          { required: true, message: "请输入一级分销比例", trigger: "change" },
        ],
        secondRatio: [
          { required: true, message: "请输入二级分销比例", trigger: "change" },
        ],
      },
    };
  },
  props: {
    coding: {
      type: [Number, String],
      required: true,
    },
  },
  watch: {
    coding: function () {
      this.getrefundOrderdetail();
    },
  },
  computed: {
    orderStatusList() {
      let arr = [
        // {
        //   label:'待支付',
        //   value:1,
        // },
        {
          label: "待确认",
          value: 2,
        },
        {
          label: "",
          value: 3,
        },
        // {
        //   label:'待评价',
        //   value:4,
        // },
        // {
        //   label:'已完成',
        //   value:5,
        // },
        {
          label: "已退款",
          value: 7,
        },
        // {
        //   label:'已取消',
        //   value:8,
        // },
        {
          label: "待预约",
          value: 9,
        },
      ];
      if (this.orderdetail.orderType == 4) {
        arr[1].label = "待领取";
      } else if (this.orderdetail.orderType == 5) {
        arr[1].label = "待使用";
      } else {
        arr[1].label = "待出行";
      }
      return arr;
    },
  },
  created() {
    this.getrefundOrderdetail();
    this.type = this.$route.query.type;
    console.log(this.type, "type");
  },
  mounted() {
    // if (this.type == 5 || this.type == 6) {
    //   this.type = false;
    // } else {
    //   this.type = true;
    // }
  },
  methods: {
    saveImgs() {
      console.log(this.imgs);
      if(this.imgs.length <= 0){
        this.$message.warning("请上传图片");
        return;
      }
      let params = {
        orderId: this.orderdetail.orderId,
        imgUrl: this.imgs.join(","),
      };
      this.$confirm("是否确认上传图片", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await uoLoadImgs(params);
          if (data.code == 0) {
            this.$message.success("上传成功");
           
            this.imgs = [];
            this.getrefundOrderdetail();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
    addImageStrs(image) {
      this.imgs = image;
      // console.log(2);
    },
    submitTimeInfo() {
      console.log(this.OrderTimeInfo.date);
      if (this.OrderTimeInfo.date.length == 0) {
        this.$message.warning("请选择时间");
        return;
      }
      let params = {
        orderId: this.orderdetail.orderId,
        remark: this.OrderTimeInfo.setTimeRemark,
        beginTime: this.OrderTimeInfo.date[0],
        endTime: this.OrderTimeInfo.date[1],
      };

      this.$confirm("是否确认修改订单时间", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await orderTime(params);
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.dialogVisibleOrderTime = false;
            this.getrefundOrderdetail();
          } else {
            this.$message.warning(data.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    orderStatusSubmit() {
      if (this.orderStatus.status == "") {
        this.$message.warning("请选择订单状态");
        return;
      }

      this.$confirm("是否确认修改订单状态", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await orderStatus({
            ...this.orderStatus,
            orderId: this.orderdetail.orderId,
          });
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.dialogVisibleOrderStatus = false;
            this.getrefundOrderdetail();
          } else {
            this.$message.warning(data.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    submitModifyInfo() {
      this.$refs.modifyInfoRef.validate(async (valid) => {
        if (valid) {
          this.modifyInfo.orderId = this.orderdetail.orderId;
          const { data } = await setModData(this.modifyInfo);
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.modifyInfoShow = false;
            this.getrefundOrderdetail();
          } else {
            this.$message.warning(data.msg);
          }
        }
      });
    },
    setMod() {
      this.modifyInfoShow = true;
    },
    submitBuyInfo() {
      this.$refs.buyInfoRef.validate(async (valid) => {
        if (valid) {
          this.buyInfo.orderId = this.orderdetail.orderId;
          const params = JSON.parse(JSON.stringify(this.buyInfo));
          delete params.totalFee;
          delete params.upFee;
          delete params.endPrice;
          delete params.refundPrice;
          const { data } = await mandatoryRefundData(this.buyInfo);
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.checkBuyInfoShow = false;
            this.getrefundOrderdetail();
          } else {
            this.$message.warning(data.msg);
          }
        }
      });
    },
    async mandatoryRefund() {
      const { data } = await checkBuyInfo({
        orderId: this.orderdetail.orderId,
      });
      if (data.code == 0) {
        this.checkBuyInfoShow = true;
        //  upFee:'',// 升级价格
        // endPrice:'', //最终支付价格
        // refundPrice:'' //已退款金额
        this.buyInfo.totalFee = data.data.totalFee;
        this.buyInfo.upFee = data.data.upFee;
        this.buyInfo.endPrice = data.data.endPrice;
        this.buyInfo.refundPrice = data.data.refundPrice;
      } else {
        this.$message.warning(data.msg);
      }
    },
    onPrint() {
      window.localStorage.setItem("printData", JSON.stringify(this.orderdetail));
      this.$router.push("/pagePrint/" + this.orderdetail.coding);
      // let { href } = this.$router.resolve({
      //   path: "/pagePrint/" + this.orderdetail.coding,
      // });
      // console.log(href);
      // href = href.substring(7);
      // window.open(href, "_blank");
    },
    //确认成团
    onGroupOk() {
      this.$confirm("是否确认成团?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await confirmGroup({
            orderId: this.orderdetail.orderId,
          });
          if (data.code == 0) {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getrefundOrderdetail();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    formatMilliseconds(value) {
      var second = parseInt(value) / 1000; // second
      var minute = 0; // minute
      var hour = 0; // hour
      if (second > 60) {
        minute = parseInt(second / 60);
        second = parseInt(second % 60);
        if (minute > 60) {
          hour = parseInt(minute / 60);
          minute = parseInt(minute % 60);
        }
      }
      var result;
      if (second >= 10) {
        result = "" + parseInt(second);
      } else {
        result = "" + "0" + parseInt(second);
      }
      if (minute >= 10) {
        result = "" + parseInt(minute) + ":" + result;
      } else {
        result = "" + "0" + parseInt(minute) + ":" + result;
      }
      if (hour >= 10) {
        result = "" + parseInt(hour) + ":" + result;
      } else {
        result = "" + "0" + parseInt(hour) + ":" + result;
      }
      return result;
    },
    //添加订单备注
    async onAddRemark() {
      const { data } = await orderAddRemark({
        orderId: this.orderdetail.orderId,
        orderRemark: this.orderRemarks,
      });
      if (data.code == 0) {
        this.$message({
          message: data.msg,
          type: "success",
        });
        this.getrefundOrderdetail();
        this.remarksShow = false;
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    //确认退款
    async onrefundOk() {
      // if(Number(this.backInfo.prodFee) + Number(this.backInfo.upFee) + Number(this.backInfo.changeFee) > this.orderdetail.refundPrice){
      //           this.$message({
      //           type: "info",
      //           message: "退款金额不能大于建议退款金额！",
      //         });
      //         return
      //         }
      this.$refs.backInfoRef.validate(async (valid) => {
        if (valid) {
          this.$confirm("是否确认退款?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              const { data } = await newBack({
                orderId: this.orderdetail.orderId,
                prodFee: this.backInfo.prodFee,
                upFee: this.backInfo.upFee,
                changeFee: this.backInfo.changeFee,
                refundIntegral: this.backInfo.refundIntegral,
              });
              if (data.code == 0) {
                this.$message({
                  message: data.msg,
                  type: "success",
                });
                this.getrefundOrderdetail();
                this.dialogVisible = false;
              } else {
                this.$message({
                  message: data.msg,
                  type: "error",
                });
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    //拒绝退款
    async onRefuseOrderOk() {
      if (this.refuseOrderReason == "") {
        return this.$message.warning("请填写拒绝退款原因");
      }
      const { data } = await refundCancel({
        orderId: this.orderdetail.orderId,
        remark: this.refuseOrderReason,
      });
      if (data.code == 0) {
        this.$message({
          message: data.msg,
          type: "success",
        });
        this.getrefundOrderdetail();
        this.refuseOrderShow = false;
        this.dialogVisible = false;
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    //拒绝退款
    onRefuseOrder() {
      this.refuseOrderShow = true;
    },
    //取消订单
    async oncancelOk() {
      if (this.cancelReason == "") {
        return this.$message.warning("请填写取消订单原因");
      }
      const { data } = await cancelOrder({
        orderId: this.orderdetail.orderId,
        remark: this.cancelReason,
      });
      if (data.code == 0) {
        this.$message({
          message: data.msg,
          type: "success",
        });
        this.getrefundOrderdetail();
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
      this.cancelOrderShow = false;
    },
    //取消订单
    onCancelOrder() {
      this.cancelOrderShow = true;
    },
    //确认订单
    async onsureOrder() {
      console.log(6666)
      if(this.orderdetail.endPrice <=0 && !this.orderdetail.fatherCoding){
        this.$confirm("0元支付订单，请和运营或客服人员确认是否是活动产品，避免造成营收损失，是否已确认并继续", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(res=>{
        this.$confirm("是否操作这条订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await confirmOrder({
            orderId: this.orderdetail.orderId,
          });
          if (data.code == 0) {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getrefundOrderdetail();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
      })
      }else{
        this.$confirm("是否操作这条订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await confirmOrder({
            orderId: this.orderdetail.orderId,
          });
          if (data.code == 0) {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getrefundOrderdetail();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
      }
      
    },
    //确认入住 游玩
    onSureComing() {
      this.$confirm("是否操作这条订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await sureComing({
            orderId: this.orderdetail.orderId,
          });
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.getrefundOrderdetail();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //获取订单详情
    async getrefundOrderdetail() {
      const { data } = await refundOrderdetail({
        coding: this.coding,
      });
      if (data.code == 0) {
        data.data[0].peopleInfos = [];
        if (data.data[0].peopleInfo) {
          data.data[0].peopleInfo.map((item) => {
            let obj = {
              trueName: item.split("_")[0],
              strType: item.split("_")[1],
              idNo: item.split("_")[2],
            };
            data.data[0].peopleInfos.push(obj);
          });
        }
        if (data.data[0].remainTime > 0) {
          data.data[0].countDown = this.formatMilliseconds(data.data[0].remainTime);
        } else {
          data.data[0].countDown = "00:00:00";
        }

        this.refundPrice = data.data[0].refundPrice;
        this.refundIntegral = data.data[0].refundIntegral;
        console.log(data.data[0], "++++++");
        this.orderdetail = data.data[0];
        this.imgs = data.data[0].masterImg || [];
      }
    },
    goOrderdetail(coding,orderType,orderId){
      if(orderType == 6){
      this.$router.push('/selectMall/order/detail?id=' + orderId)
      }else{
        document.getElementById("refundOrderdetail").scrollIntoView({ behavior: "smooth" });
      this.$router.push(`/refundOrderdetail/${coding}?type=${this.type}`);
      }
      
      
    },
    // 退款限制
    astrictProdFee(){
      if(Number(this.backInfo.prodFee) > Number(this.orderdetail.totalPrice)){
        this.backInfo.prodFee = Number(this.orderdetail.totalPrice)
      }
    },
    astrictUpFee(){
      if(Number(this.backInfo.upFee) > Number(this.orderdetail.upgradePrice)){
        this.backInfo.upFee = Number(this.orderdetail.upgradePrice)
      }
    },
    astrictChangeFee(){
      if(Number(this.backInfo.changeFee) > Number(this.orderdetail.changeFee)){
        this.backInfo.changeFee = Number(this.orderdetail.changeFee)
      }
    },
  },
};
</script>
<style lang="less" scoped>
.refundOrderdetail {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .return {
    margin-top: 50px;
  }
  .el-table {
    margin-top: 30px;
  }
  /deep/.el-form {
    margin-top: 30px;
    .palyer {
      .el-form-item__content {
        display: flex;
        flex-direction: column;
        align-items: unset !important;
        div {
          span {
            &:nth-child(2) {
              margin: 0 10px 0 25px;
            }
          }
        }
      }
    }
    .el-form-item {
      .el-form-item__label {
        width: 120px !important;
      }
      .el-form-item__content {
        margin-left: 120px !important;
        display: flex;
        // align-items: center;
        // justify-content: space-between;
        .el-button {
          margin-left: 15px;
        }
        div {
          display: inline-block;
          width: 250px;
        }
        .name {
          margin: 0 8px 0 40px;
          color: #606266;
        }
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    margin-top: 20px;
    div {
      &:nth-child(1) {
        width: 5px;
        height: 13px;
        background: #6a8abe;
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
}
</style>
